import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {  Link } from "gatsby"

const Contact = () => (
  <Layout>
    <SEO title="Hakkımızda" />
    <h1>Hakkımızda</h1>
    <section className="section">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div className="position-relative">
          <img
            src="https://res.cloudinary.com/carrara-pilates/image/upload/t_Optimize/v1585161301/gallery/studio/gallery_carrara_148_w0vymr.jpg"
            className="rounded img-fluid mx-auto d-block"
            alt='Carrara Pilates'
          />
        </div>
      </div>
      {/*end col*/}
      <div className="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div className="section-title ml-lg-4">
          <h4 className="title mb-4">Biz Kimiz?</h4>
          <p className="text-muted">
            <span className="text-primary font-weight-bold">Carrara Pilates </span> 
           Anadolu Yakası'nda faaliyet gösteren, profesyonel kadrosuyla hijyenik ortamda faaliyet gösteren pilates ve fitness stüdyosudur.
          </p>
          <Link to="/iletisim" className="btn btn-primary mt-3">
            Hemen Kaydol <i className="mdi mdi-chevron-right" />
          </Link>
        </div>
      </div>
      {/*end col*/}
    </div>
    {/*end row*/}
  </div>

</section>;

  </Layout>
)

export default Contact
